import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import { configureStore } from "./store";

import { LoadingContextProvider } from "./store/loading-context";

import process from "process";
window.process = process;

ReactDOM.render(
  <Provider store={configureStore({})}>
    <React.Fragment>
      <LoadingContextProvider>
        <App />
      </LoadingContextProvider>
    </React.Fragment>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
