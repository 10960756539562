import React, { useEffect, useState, useRef } from "react";
import JsonFormatter from "react-json-formatter";

// import CompanyService from "../../../../services/User/CompanyService";

const ComplaintApi = () => {
  useEffect(() => {
    fetchData();
  }, []);

  const [company, setCompany] = useState();

  const fetchData = async () => {
    // const _company = await CompanyService.getCompanyData();
    // if (_company) {
    //   setCompany(_company);
    // }
  };

  const jsonStyle = {
    propertyStyle: { color: "red" },
    stringStyle: { color: "green" },
    numberStyle: { color: "darkorange" },
  };

  const sampleRequest = `https://api.formaxpay.com/api/recharge/complaint?authorization=UVRNRlRKckJjRWt5RnBETGtCSGpkV0dMYnF&order_id=TXN90600585937504`;

  const sampleResponse = {
    status: "SUCCESS",
    message: "Complaint Registered Successfully",
  };

  const request_params = [
    {
      param_name: "authorization",
      type: "String",
      is_required: "Yes",
      description: "The Authorization will provided by Formax Pay Team",
    },
    {
      param_name: "order_id",
      type: "String",
      is_required: "Yes",
      description: "Our System Order ID",
    },
  ];

  const response_params = [
    {
      param_name: "status",
      description:
        "It indicates the request status, with possible values: 'SUCCESS' or 'ERROR'",
    },
    {
      param_name: "message",
      description: "It indicates the success or failed message",
    },
  ];

  return (
    <div className="">
      <h2 className="docs-h4">Complaint API</h2>
      <p className="docs-p">
        The Complaint API is used when a recharge transaction is marked as
        successful, but the customer did not receive the benefits. This API
        allows the submission of a complaint for further investigation and
        resolution.
      </p>
      <h2 className="docs-h4">Sample Request (GET/POST Method)</h2>
      <div className="docs-example">
        <code className="docs-code">GET {sampleRequest}</code>
      </div>

      <h2 className="docs-h4">Request Parameters</h2>
      <table className="docs-table">
        <thead>
          <tr>
            <th className="docs-th">Parameter</th>
            <th className="docs-th">Type</th>
            <th className="docs-th">Required</th>
            <th className="docs-th">Description</th>
          </tr>
        </thead>
        <tbody>
          {request_params.map((e, key) => (
            <tr key={key}>
              <td className="docs-td">
                <code className="docs-code">{e.param_name}</code>
              </td>
              <td className="docs-td">{e.type}</td>
              <td className="docs-td">{e.is_required}</td>
              <td className="docs-td">{e.description}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <h2 className="docs-h4">Sample Response (JSON Format)</h2>
      <pre className="docs-pre">
        <code className="docs-code">
          <JsonFormatter
            json={sampleResponse}
            //  tabWith="4"
            jsonStyle={jsonStyle}
          />
        </code>
      </pre>
      <h2 className="docs-h4">Response Parameters</h2>
      <table className="docs-table">
        <thead>
          <tr>
            <th className="docs-th">Parameter</th>
            <th className="docs-th">Description</th>
          </tr>
        </thead>
        <tbody>
          {response_params.map((e, key) => (
            <tr key={key}>
              <td className="docs-td">
                <code className="docs-code">{e.param_name}</code>
              </td>
              <td className="docs-td">{e.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ComplaintApi;
