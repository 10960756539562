import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Templete from "../Templete/Templete";

import TransactionApi from "./API-docs/TransactionApi";
import BalanceApi from "./API-docs/BalanceApi";
import TxnStatusApi from "./API-docs/TxnStatusApi";

import "./API-docs/style.css";
import ComplaintApi from "./API-docs/ComplaintApi";
import OperatorListApi from "./API-docs/OperatorListApi";
import WebhookApi from "./API-docs/WebhookApi";

const ApiCredentials = () => {
  const history = useHistory();

  return (
    <React.Fragment>
      <Templete t1="Home" t2="API Credentials">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-default">
                  <div class="card-header">
                    <h3 class="card-title">API Credentials</h3>

                    <div class="card-tools">
                      <button
                        type="button"
                        class="btn btn-sm bg-gradient-success"
                        onClick={() => {
                          history.push("/user/api_documentation");
                        }}
                      >
                        <i class="fa fa-code"></i> API Documentation
                      </button>
                    </div>
                  </div>

                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 ">
                        <div class="card card-primary">
                          <div class="card-body">
                            Access Denied. Please contact the administrator.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default ApiCredentials;
