import React, { useEffect, useState, useRef } from "react";
import JsonFormatter from "react-json-formatter";

// import CompanyService from "../../../../services/User/CompanyService";

const WebhookApi = () => {
  useEffect(() => {
    fetchData();
  }, []);

  const [company, setCompany] = useState();

  const fetchData = async () => {
    // const _company = await CompanyService.getCompanyData();
    // if (_company) {
    //   setCompany(_company);
    // }
  };

  const jsonStyle = {
    propertyStyle: { color: "red" },
    stringStyle: { color: "green" },
    numberStyle: { color: "darkorange" },
  };

  const sampleRequest = `https://www.yourdomain.com/recharge_response?order_id=R01196289062500IJMKC&p_order_id=P1234567892&number=9888877777&amount=10&status=SUCCESS&message=Transaction Successful&operator_ref_id=KOH2306261059220003`;

  //   const sampleResponse = {
  //     status: "SUCCESS",
  //     message: "SUCCESS",
  //     balance: "505.00",
  //   };

  const request_params = [
    {
      param_name: "order_id",
      type: "String",
      is_required: "Yes",
      description: "Our System Order ID",
    },
    {
      param_name: "p_order_id",
      type: "String",
      is_required: "Yes",
      description: "Partner System Order ID",
    },
    {
      param_name: "number",
      type: "String",
      is_required: "Yes",
      description: "Recharge Number",
    },
    {
      param_name: "amount",
      type: "String",
      is_required: "Yes",
      description: "Recharge Amount",
    },
    {
      param_name: "status",
      type: "String",
      is_required: "Yes",
      description:
        "It indicates the recharge transaction status with possible values: 'SUCCESS', 'FAILED', 'PENDING'",
    },
    {
      param_name: "message",
      type: "String",
      is_required: "Yes",
      description: "It indicates the success or failed message",
    },
    {
      param_name: "operator_ref_id",
      type: "String",
      is_required: "Yes",
      description: "Operator Reference ID",
    },
  ];

  //   const response_params = [
  //     {
  //       param_name: "status",
  //       description:
  //         "It indicates the request status, with possible values: 'SUCCESS' or 'ERROR'",
  //     },
  //     {
  //       param_name: "message",
  //       description: "It indicates the success or failed message",
  //     },
  //     {
  //       param_name: "balance",
  //       description: "Available Balance",
  //     },
  //   ];

  return (
    <div className="">
      <h2 className="docs-h4">Callback Response</h2>
      <p className="docs-p">
        Partners must update their Callback URL in our system, and we will send
        transaction status updates via GET request when the service provider
        updates the status.
      </p>
      <h2 className="docs-h4">Sample Request (GET Method)</h2>
      <div className="docs-example">
        <code className="docs-code">GET {sampleRequest}</code>
      </div>

      <h2 className="docs-h4">Request Parameters</h2>
      <table className="docs-table">
        <thead>
          <tr>
            <th className="docs-th">Parameter</th>
            <th className="docs-th">Type</th>
            {/* <th className="docs-th">Required</th> */}
            <th className="docs-th">Description</th>
          </tr>
        </thead>
        <tbody>
          {request_params.map((e, key) => (
            <tr key={key}>
              <td className="docs-td">
                <code className="docs-code">{e.param_name}</code>
              </td>
              <td className="docs-td">{e.type}</td>
              {/* <td className="docs-td">{e.is_required}</td> */}
              <td className="docs-td">{e.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WebhookApi;
