import React, { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import BarLoader from "react-spinners/BarLoader";
import { useHistory } from "react-router-dom";
import Templete from "../Templete/Templete";

import AuthService from "../../../services/User/AuthService";
import ProfileService from "../../../services/User/ProfileService";
import DbModuleService from "../../../services/User/DbModuleService";
import NoticeService from "../../../services/User/NoticeService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import NoticeDialog from "./NoticeDialog";

import "./style.css";
import UtilityServicesBox from "./ServicesBox/UtilityServicesBox";
import RechargeServicesBox from "./ServicesBox/RechargeServicesBox";
import EgovServicesBox from "./ServicesBox/EGovServicesBox";
import InsuranceServicesBox from "./ServicesBox/InsuranceServicesBox";

const Dashboard = () => {
  const history = useHistory();
  const loadingCtx = useContext(LoadingContext);
  const [isLoading, setIsLoading] = useState(false);
  const [wallet, setWallet] = useState();
  const [data, setData] = useState(false);

  const [sessionUserType, setSessionUserType] = useState();
  const [name, setName] = useState();
  const [userId, setUserId] = useState();
  const [mobile, setMobile] = useState();

  useEffect(() => {
    // getBalance();
    getTotalBalance();
    // earningDetails();
    getUserType();
    // window.location.reload();
    // fetchAllNotice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserType = () => {
    const _userType = AuthService.getUserType();
    const _name = AuthService.getName();
    const _userId = AuthService.getUserID();
    const _mobile = AuthService.getMobile();

    setName(_name);
    setUserId(_userId);
    setMobile(_mobile);
    setSessionUserType(_userType);
  };

  const getBalance = async () => {
    // loadingCtx.startLoading();
    setIsLoading(true);

    try {
      const token = AuthService.getAccessToken();

      const responseData = await ProfileService.getBalance(token);
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setWallet(responseData.data);
      } else {
        if (message == "Unauthorized Access") {
          AuthService.logout();
          window.location.href = "/login";
        }
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    // loadingCtx.stopLoading();
    setIsLoading(false);
  };

  const getTotalBalance = async () => {
    // loadingCtx.startLoading();
    setIsLoading(true);

    try {
      const token = AuthService.getAccessToken();

      const responseData = await ProfileService.totalBalance(token);
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setData(responseData.data);
      } else {
        if (message == "Unauthorized Access") {
          AuthService.logout();
          window.location.href = "/login";
        }
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    // loadingCtx.stopLoading();
    setIsLoading(false);
  };

  const [earningData, setEarningData] = useState();

  const earningDetails = async () => {
    // loadingCtx.startLoading();
    // setIsLoading(true);

    try {
      const token = AuthService.getAccessToken();

      const responseData = await ProfileService.fetchEarningDetails(token);
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setEarningData(responseData.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    // loadingCtx.stopLoading();
    //setIsLoading(false);
  };

  const autoLoginHandler = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await DbModuleService.adminLogin(token);
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const accessToken = responseData.data.access_token;
        const name = responseData.data.name;
        const user_id = responseData.data.user_id;
        const user_type = responseData.data.user_type;

        localStorage.setItem("admin_timestamp", new Date().getTime());
        localStorage.setItem("admin_access_token", accessToken);
        localStorage.setItem(
          "admin",
          JSON.stringify({
            name: name,
            user_type: user_type,
            user_id: user_id,
          })
        );

        window.open("/admin/dashboard", "_blank", "");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  function percentage(numVal1, numVal2) {
    let percentage = (numVal1 / numVal2) * 100;
    return percentage.toFixed(2);
  }

  const [open, setOpen] = useState(false);
  const [noticeData, setNoticeData] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  // const fetchAllNotice = async () => {
  //   loadingCtx.startLoading();

  //   try {
  //     const token = AuthService.getAccessToken();

  //     const responseData = await NoticeService.fetchAll(token);
  //     const status = responseData.status;
  //     const message = responseData.message;

  //     if (status === "SUCCESS") {
  //       const data = responseData.data;
  //       setNoticeData(responseData.data);

  //       if (data.length > 0) {
  //         setOpen(true);
  //       }
  //     } else {
  //       Swal.fire("Opps!", message, "error");
  //     }
  //   } catch (error) {
  //     const errMsg = HttpError.getError(error);
  //     Swal.fire("Opps!", errMsg, "error");
  //   }
  //   loadingCtx.stopLoading();
  // };

  return (
    <React.Fragment>
      <Templete isHide={true} t1="Home" t2="Dashboard">
        <section class="content">
          <div class="container-fluid">
            {/* <div className="row">
              <div class="col-xl-6 cstm-bg">
                <div class="card cstm-bg-dark">
                  <div class="card-body ">
                    <div class="row">
                      <div class="col-md-6" style={{ color: "#fff" }}>
                        <div>Welcome! {name}</div>
                        <div>User ID: {userId}</div>
                      </div>
                      <div
                        class="col-md-6"
                        style={{ color: "#fff", textAlign: "right" }}
                      >
                        <div>Rupe Pro Balance</div>
                        <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                          {data ? "₹" + data.main_wallet : "..."}
                          <i
                            onClick={getBalance}
                            class="fas fa-redo"
                            style={{ paddingLeft: "10px", fontSize: "15px" }}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6 justify-content-center">
                <div class="card cstm-bg-dark">
                  <div class="card-body ">
                    <div class="row justify-content-center">
                      <div
                        class="col-sm"
                        style={{ color: "#fff", textAlign: "center" }}
                      >
                        <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                          {earningData ? "₹" + earningData.today_earning : "-"}
                        </div>
                        <div>Today Earning</div>
                      </div>
                      <div
                        class="col-sm"
                        style={{ color: "#fff", textAlign: "center" }}
                      >
                        <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                          {earningData
                            ? "₹" + earningData.current_month_earning
                            : "-"}
                        </div>
                        <div>Current Month Earning</div>
                      </div>
                      <div
                        class="col-sm"
                        style={{ color: "#fff", textAlign: "center" }}
                      >
                        <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                          {earningData
                            ? earningData.current_month_txns_count
                            : "-"}
                        </div>
                        <div>Current Month Transactions</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="card cstm-bg-dark">
                  <div class="card-header">
                    <h3
                      class="card-title text-center"
                      // style={{ fontSize: "20px", fontWeight: "600" }}
                    >
                      Main Account
                    </h3>
                    <span
                      className="float-right"
                      // style={{ fontSize: "20px", fontWeight: "bold" }}
                    >
                      <img src="/assets/img/favicon.png" width={27} />
                      {/* {data ? "₹" + data.main_wallet : "..."} */}
                    </span>
                  </div>
                  <div
                    class="card-body"
                    style={{ padding: "15px 15px 5px 15px" }}
                  >
                    <div class="row">
                      <div class="col-6">
                        <h6>Available Balance</h6>
                        <div
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "green",
                          }}
                        >
                          {data ? "₹" + data.main_wallet : "..."}
                          <i
                            onClick={getTotalBalance}
                            class="fas fa-redo"
                            style={{ paddingLeft: "10px", fontSize: "15px" }}
                          ></i>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div className="">
                          <a
                            class="btn btn-app"
                            onClick={() => {
                              history.push("/user/add_money");
                            }}
                          >
                            <i class="fas fa-rupee-sign"></i> Add Money
                          </a>

                          <a
                            class="btn btn-app"
                            onClick={() => {
                              history.push("/user/wallet_statement");
                            }}
                          >
                            <i class="fas fa-wallet"></i> Statement
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-6">
                <div class="small-box card">
                  <div class="inner">
                    <h5>
                      ₹{data ? data.today_wallet_topup[0]["total_amount"] : ""}
                    </h5>
                    <p>Today Wallet Topup</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-wallet"></i>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-6">
                <div class="small-box card">
                  <div class="inner">
                    <h5>
                      ₹{data ? data.today_earning[0]["total_amount"] : ""}
                    </h5>
                    <p>Today Earning</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-rupee-sign"></i>
                  </div>
                </div>
              </div>

              {/* <div class="col-lg-2 col-6">
                <div class="small-box card">
                  <div class="inner">
                    <h5>₹{data ? data.main_wallet : ""}</h5>
                    <p>Available Balance</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-rupee-sign"></i>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-6">
                <div class="small-box card">
                  <div class="inner">
                    <h5>₹{data ? data.main_wallet : ""}</h5>
                    <p>Available Balance</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-wallet"></i>
                  </div>
                </div>
              </div> */}
            </div>

            <div className="mb-3">
              <BarLoader color="#42ba96" loading={isLoading} width="100%" />
            </div>

            <div className="card">
              <div class="card-header">
                <h3
                  class="card-title"
                  style={
                    {
                      // fontSize: "20px",
                      // fontWeight: "600"
                    }
                  }
                >
                  Services
                </h3>
              </div>
              <div className="card-body">
                <RechargeServicesBox />
                {/* <UtilityServicesBox /> */}
                <EgovServicesBox />
                <InsuranceServicesBox />
              </div>
            </div>

            <div className="pt-1"></div>
          </div>
        </section>
      </Templete>

      <NoticeDialog
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        noticeData={noticeData}
      />
    </React.Fragment>
  );
};

export default Dashboard;
