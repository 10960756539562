import React, { useRef, useState, useContext } from "react";
import Swal from "sweetalert2";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import PackageService from "../../../services/Admin/PackageService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const AddPackage = () => {
  const loadingCtx = useContext(LoadingContext);

  const [status, setStatus] = useState();

  const formRef = useRef();
  const nameRef = useRef();

  const addPackageHandler = async () => {
    const name = nameRef.current.value;

    if (!name) {
      Swal.fire("Opps!", "Invalid Name", "error");
      return;
    }

    if (!status) {
      Swal.fire("Opps!", "Invalid Status", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await PackageService.add(token, name, status);
      const status1 = responseData.status;
      const message = responseData.message;

      if (status1 === "SUCCESS") {
        formRef.current.reset();
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Add Package">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Add Package</h3>
                  </div>

                  <div class="row">
                    <div class="col-md-6 mx-auto">
                      <form ref={formRef} autoComplete="off">
                        <div class="card-body">
                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Name</label>
                            <div class="col-sm-10">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Name"
                                ref={nameRef}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">
                              Status
                            </label>
                            <div class="col-sm-10">
                              <select
                                name="title"
                                class="form-control"
                                onChange={(e) => {
                                  setStatus(e.target.value);
                                }}
                              >
                                <option value="">---Select Status---</option>
                                <option value="Enabled">Enabled</option>
                                <option value="Disabled">Disabled</option>
                              </select>
                            </div>
                          </div>

                          <div class="text-center">
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={addPackageHandler}
                            >
                              Submit
                            </button>
                            <button type="reset" class="btn btn-default ml-5">
                              Reset
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default AddPackage;
