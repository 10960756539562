import React from "react";
import { Redirect } from "react-router-dom";
import { adminAuthProtectedRoutes } from "./AdminRoutes";

import AdminLogin from "../pages/Admin/Login/Login";
import Login from "../pages/User/Login/Login";
import ForgotPassword from "../pages/User/ForgotPassword/ForgotPassword";

import Dashboard from "../pages/User/Dashboard/Dashboard";
import ChangePassword from "../pages/User/Profile/ChangePassword";
import Logout from "../pages/User/Logout/Logout";
import ResetPin from "../pages/User/ResetPIN/ResetPin";
import MyProfile from "../pages/User/Profile/MyProfile";
import UserListTreeView from "../pages/User/ManageUser/UserListTreeView";

import NewInvestmentRequest from "../pages/User/InvestmentModule/NewInvestmentRequest";
import InvestmentRequestHistory from "../pages/User/InvestmentModule/InvestmentRequestHistory";
import IncomeSummary from "../pages/User/IncomeReport/IncomeSummary";
import InvestmentHistory from "../pages/User/InvestmentModule/InvestmentHistory";
import NewWithdrawalRequest from "../pages/User/WithdrawalModule/NewWithdrawalRequest";
import WithdrawalHistory from "../pages/User/WithdrawalModule/WithdrawalHistory";
import UserListSso from "../pages/Sso/UserListSso";
import UserListTreeViewSso from "../pages/User/ManageUser/UserListTreeViewSso";
import TeamInvestmentHistory from "../pages/User/InvestmentModule/TeamInvestmentHistory";
import CommissionHistory from "../pages/User/IncomeReport/CommissionHistory";
import AddMoney from "../pages/User/AddMoney/AddMoney";
import WalletStatement from "../pages/User/Wallet/WalletStatement";
import CommissionSummary from "../pages/User/Wallet/CommissionSummary";
import FundRequestHistory from "../pages/User/Wallet/FundRequestHistory";
import WalletTransferHistory from "../pages/User/Wallet/WalletTransferHistory";
import WalletReceivedHistory from "../pages/User/Wallet/WalletReceivedHistory";
import InsuranceDekho from "../pages/User/Services/Insurance/InsuranceDekho";
import UtiitslPANCard from "../pages/User/Services/UtiitslPANCard/UtiitslPANCard";
import NsdlPANCard from "../pages/User/Services/NsdlPANCard/NsdlPANCard";
import AddUser from "../pages/User/DbModule/AddUser";
import UserList from "../pages/User/DbModule/UserList";
import Recharge from "../pages/User/Services/Recharge/Recharge";
import NsdlPANCardReport from "../pages/User/Services/NsdlPANCard/NsdlPANCardReport";
import DeveloperAPI from "../pages/User/DeveloperAPI/DeveloperAPI";
import AddPackage from "../pages/User/CommercialMaster/AddPackage";
import PackageList from "../pages/User/CommercialMaster/PackageList";
import SetCommercial from "../pages/User/CommercialMaster/SetCommercial";
import NsdlReceipt from "../pages/User/Services/NsdlPANCard/NsdlReceipt";
import NotFound from "../pages/NotFound";
import BillPayment from "../pages/User/Services/BillPayment/BillPayment";
import BillPaymentReport from "../pages/User/Services/BillPayment/BillPaymentReport";
import RechargeReport from "../pages/User/Services/Recharge/RechargeReport";
import UtiitslRevampPANCard from "../pages/User/Services/UtiitslPANCard/UtiitslRevampPANCard";
import UtiitslPaymentProcess from "../pages/User/Services/UtiitslPANCard/UtiitslPaymentProcess";
import ApiDocumentation from "../pages/User/API-docs/ApiDocumentation";
import ApiCredentials from "../pages/User/API-docs/APICredentials";

const authProtectedRoutes = [
  { path: "/user/dashboard", component: Dashboard },
  { path: "/dashboard", component: Dashboard },
  { path: "/user/change_password", component: ChangePassword },
  { path: "/user/reset_pin", component: ResetPin },
  { path: "/user/my_profile", component: MyProfile },
  { path: "/user/add_user", component: AddUser },
  { path: "/user/user_list_tree_view", component: UserListTreeView },
  { path: "/user/new_investment_request", component: NewInvestmentRequest },
  {
    path: "/user/investment_request_history",
    component: InvestmentRequestHistory,
  },
  {
    path: "/user/investment_history",
    component: InvestmentHistory,
  },
  {
    path: "/user/team_investment_history",
    component: TeamInvestmentHistory,
  },
  {
    path: "/user/income_summary",
    component: IncomeSummary,
  },
  {
    path: "/user/commission_history",
    component: CommissionHistory,
  },
  {
    path: "/user/new_withdrawal_request",
    component: NewWithdrawalRequest,
  },
  {
    path: "/user/withdrawal_history",
    component: WithdrawalHistory,
  },
  { path: "/user/logout", component: Logout },
  { path: "/sso/user_list", component: UserListSso },
  { path: "/sso/user_list_tree_view", component: UserListTreeViewSso },
  { path: "/user/add_money", component: AddMoney },
  { path: "/user/wallet_statement", component: WalletStatement },
  { path: "/user/commission_summary", component: CommissionSummary },
  { path: "/user/fund_request_history", component: FundRequestHistory },
  { path: "/user/wallet_transfer_history", component: WalletTransferHistory },
  { path: "/user/wallet_received_history", component: WalletReceivedHistory },
  { path: "/user/insurance", component: InsuranceDekho },
  { path: "/user/utiitsl_pancard", component: UtiitslPANCard },
  { path: "/user/utiitsl_revamp_pancard", component: UtiitslRevampPANCard },
  { path: "/user/nsdl_pancard/:mode", component: NsdlPANCard },
  { path: "/user/add_user", component: AddUser },
  { path: "/user/user_list", component: UserList },
  { path: "/user/recharge/:type", component: Recharge },
  { path: "/user/recharge_report", component: RechargeReport },
  { path: "/user/bill_payment/:category", component: BillPayment },
  { path: "/user/bill_payment_report", component: BillPaymentReport },
  { path: "/user/nsdl_pancard_report", component: NsdlPANCardReport },
  { path: "/user/developer_api", component: DeveloperAPI },
  { path: "/user/add_package", component: AddPackage },
  { path: "/user/package_list", component: PackageList },
  { path: "/user/set_commercial", component: SetCommercial },
  {
    path: "/user/api_credentials",
    component: ApiCredentials,
  },
  {
    path: "/user/api_documentation",
    component: ApiDocumentation,
  },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/login" />,
  },
];

const publicRoutes = [
  // {
  //   path: "/",
  //   exact: true,
  //   component: () => <Redirect to="/admin_login" />,
  // },
  // {
  //   path: "/",
  //   exact: true,
  //   component: () => <Redirect to="/admin_login" />,
  // },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/login" />,
  },
  { path: "/login", component: Login },
  { path: "/forgot_password", component: ForgotPassword },
  { path: "/admin_login", component: AdminLogin },
  { path: "/user/nsdl_receipt", component: NsdlReceipt },
  { path: "/nsdl_acknowledgement_receipt", component: NsdlReceipt },
  {
    path: "/utiitsl_payment_process",
    component: UtiitslPaymentProcess,
  },
];

export { adminAuthProtectedRoutes, authProtectedRoutes, publicRoutes };
