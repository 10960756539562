import React, { useRef, useState, useEffect, useContext } from "react";
import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Swal from "sweetalert2";
import { ThemeProvider, createTheme } from "@mui/material";

import Templete from "../Templete/Templete";
import AuthService from "../../../services/Admin/AuthService";
import PackageService from "../../../services/Admin/PackageService";
import RechargeService from "../../../services/Admin/RechargeService";
import ProductService from "../../../services/Admin/ProductService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const PackageSwitchRechargeApi = (props) => {
  const packageId = props.id ? props.id : props.match.params.id;

  const loadingCtx = useContext(LoadingContext);

  const [products, setProducts] = React.useState();
  const [rechargeApis, setRechargeApis] = React.useState([]);

  useEffect(() => {
    fetchAllOperator();
    fetchApis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAllOperator = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const data = await PackageService.fetchPackageRechargeApi(
        token,
        packageId
      );

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        setProducts(data.data.custom_recharge_api);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const fetchApis = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const data = await RechargeService.fetchAllRechargeApi(token);

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        setRechargeApis(data.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const apiName1Ref = useRef();
  const apiName2Ref = useRef();
  const apiName3Ref = useRef();
  const apiName4Ref = useRef();
  const apiName5Ref = useRef();

  const columns = [
    {
      title: "Operator Name",
      field: "product_name",
      editable: "never",
    },
    {
      title: "API_1",
      field: "api1",
      render: (rowData) => {
        const selectedApi = rechargeApis.find((api) => api.id === rowData.api1);
        return selectedApi ? (
          <span style={{ fontWeight: "bold", color: "#4caf50" }}>
            {selectedApi.name}
          </span>
        ) : (
          "Not Selected"
        );
      },
      editComponent: (data) => (
        <Autocomplete
          options={rechargeApis}
          defaultValue={
            data.rowData.api1
              ? rechargeApis.find((v) => v.id === data.rowData.api1)
              : ""
          }
          getOptionLabel={(option) => (option.name ? option.name : "")}
          style={{ width: 300 }}
          // onChange={selectedApiIdChangeHandler}
          //   onChange={(e, value) => setSelectedApiId(value ? value.id : "")}
          renderInput={(params) => (
            <TextField
              inputRef={apiName1Ref}
              {...params}
              label="Select Recharge API"
              margin="normal"
            />
          )}
        />
      ),
    },
    {
      title: "API_2",
      field: "api2",
      render: (rowData) => {
        const selectedApi = rechargeApis.find((api) => api.id === rowData.api2);
        return selectedApi ? (
          <span style={{ fontWeight: "bold", color: "#4caf50" }}>
            {selectedApi.name}
          </span>
        ) : (
          "Not Selected"
        );
      },
      editComponent: (data) => (
        <Autocomplete
          options={rechargeApis}
          defaultValue={
            data.rowData.api2
              ? rechargeApis.find((v) => v.id === data.rowData.api2)
              : ""
          }
          getOptionLabel={(option) => (option.name ? option.name : "")}
          style={{ width: 300 }}
          // onChange={selectedApiIdChangeHandler}
          //   onChange={(e, value) => setSelectedApiId(value ? value.id : "")}
          renderInput={(params) => (
            <TextField
              inputRef={apiName2Ref}
              {...params}
              label="Select Recharge API"
              margin="normal"
            />
          )}
        />
      ),
    },
    {
      title: "API_3",
      field: "api3",
      render: (rowData) => {
        const selectedApi = rechargeApis.find((api) => api.id === rowData.api3);
        return selectedApi ? (
          <span style={{ fontWeight: "bold", color: "#4caf50" }}>
            {selectedApi.name}
          </span>
        ) : (
          "Not Selected"
        );
      },
      editComponent: (data) => (
        <Autocomplete
          options={rechargeApis}
          defaultValue={
            data.rowData.api3
              ? rechargeApis.find((v) => v.id === data.rowData.api3)
              : ""
          }
          getOptionLabel={(option) => (option.name ? option.name : "")}
          style={{ width: 300 }}
          // onChange={selectedApiIdChangeHandler}
          //   onChange={(e, value) => setSelectedApiId(value ? value.id : "")}
          renderInput={(params) => (
            <TextField
              inputRef={apiName3Ref}
              {...params}
              label="Select Recharge API"
              margin="normal"
            />
          )}
        />
      ),
    },
    // {
    //   title: "API (Route 4)",
    //   field: "rechargeApi4.name",
    //   editComponent: (data) => (
    //     <Autocomplete
    //       options={rechargeApis}
    //       defaultValue={
    //         data.rowData.rechargeApi4
    //           ? rechargeApis.find(
    //               (v) => v.name === data.rowData.rechargeApi4.name
    //             )
    //           : ""
    //       }
    //       getOptionLabel={(option) => (option.name ? option.name : "")}
    //       style={{ width: 300 }}
    //       // onChange={selectedApiIdChangeHandler}
    //       //   onChange={(e, value) => setSelectedApiId(value ? value.id : "")}
    //       renderInput={(params) => (
    //         <TextField
    //           inputRef={apiName4Ref}
    //           {...params}
    //           label="Select Recharge API"
    //           margin="normal"
    //         />
    //       )}
    //     />
    //   ),
    // },
    // {
    //   title: "API (Route 5)",
    //   field: "rechargeApi5.name",
    //   editComponent: (data) => (
    //     <Autocomplete
    //       options={rechargeApis}
    //       defaultValue={
    //         data.rowData.rechargeApi5
    //           ? rechargeApis.find(
    //               (v) => v.name === data.rowData.rechargeApi5.name
    //             )
    //           : ""
    //       }
    //       getOptionLabel={(option) => (option.name ? option.name : "")}
    //       style={{ width: 300 }}
    //       // onChange={selectedApiIdChangeHandler}
    //       //   onChange={(e, value) => setSelectedApiId(value ? value.id : "")}
    //       renderInput={(params) => (
    //         <TextField
    //           inputRef={apiName5Ref}
    //           {...params}
    //           label="Select Recharge API"
    //           margin="normal"
    //         />
    //       )}
    //     />
    //   ),
    // },
  ];

  const defaultMaterialTheme = createTheme({});

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Package Switch Recharge API">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Package Switch Recharge API</h3>
                  </div>

                  <div class="card-body mb-5">
                    <ThemeProvider theme={defaultMaterialTheme}>
                      <MaterialTable
                        components={{
                          Container: (props) => (
                            <Paper {...props} elevation={0} />
                          ),
                        }}
                        columns={columns}
                        data={products}
                        options={{
                          search: false,
                          paging: false,
                          showTitle: false,
                        }}
                        editable={{
                          onRowUpdate: (newData, oldData) =>
                            new Promise(async (resolve, reject) => {
                              console.log(newData);
                              console.log(oldData);

                              loadingCtx.startLoading();

                              const apiName1 = apiName1Ref.current.value;
                              const fData1 = rechargeApis.find((element) => {
                                return element.name === apiName1;
                              });

                              const apiName2 = apiName2Ref.current.value;
                              const fData2 = rechargeApis.find((element) => {
                                return element.name === apiName2;
                              });

                              const apiName3 = apiName3Ref.current.value;
                              const fData3 = rechargeApis.find((element) => {
                                return element.name === apiName3;
                              });

                              // const apiName4 = apiName4Ref.current.value;
                              // const fData4 = rechargeApis.find((element) => {
                              //   return element.name === apiName4;
                              // });

                              // const apiName5 = apiName5Ref.current.value;
                              // const fData5 = rechargeApis.find((element) => {
                              //   return element.name === apiName5;
                              // });

                              const token = AuthService.getAccessToken();

                              PackageService.packageSwitchRechargeApi(
                                token,
                                packageId,
                                oldData.product_id,
                                oldData.product_name,
                                fData1 ? fData1.id : null,
                                fData2 ? fData2.id : null,
                                fData3 ? fData3.id : null
                                // fData4 ? fData4.id : null,
                                // fData5 ? fData5.id : null
                              )
                                .then((data) => {
                                  loadingCtx.stopLoading();
                                  fetchAllOperator();
                                })
                                .catch((error) => {
                                  loadingCtx.stopLoading();
                                  fetchAllOperator();
                                });

                              resolve();
                            }),

                          onRowDelete: (oldData) =>
                            new Promise((resolve, reject) => {
                              setTimeout(() => {
                                resolve();
                              }, 1000);
                            }),
                        }}
                      />
                    </ThemeProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default PackageSwitchRechargeApi;
