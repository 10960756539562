import React from "react";
import JsonFormatter from "react-json-formatter";

const TxnStatusApi = () => {
  const jsonStyle = {
    propertyStyle: { color: "red" },
    stringStyle: { color: "green" },
    numberStyle: { color: "darkorange" },
  };

  const sampleRequest = `https://api.formaxpay.com/api/recharge/txn_status?authorization=UVRNRlRKckJjRWt5RnBETGtCSGpkV0dMYnF&order_id=TXN90600585937504&flat_object=false`;

  const sampleResponse = {
    status: "SUCCESS",
    message: "Transaction Details Fetched Successfully",
    txn_status: "SUCCESS",
    txn_description: "Transaction Successful",
    order_id: "R51318359375000FQGCZ",
    p_order_id: "TXN90600585937504",
    operator_id: 1,
    operator_name: "Airtel",
    number: "9836114520",
    amount: 10,
    operator_ref_id: "4487411496",
    txn_type: "DR",
    txn_amount: 9.8,
    opening_bal: 505,
    closing_bal: 495.2,
    timestamp: "2022-01-01 21:6:24",
  };

  const request_params = [
    {
      param_name: "authorization",
      type: "String",
      is_required: "Yes",
      description: "The Authorization will provided by Formax Pay Team",
    },
    {
      param_name: "order_id",
      type: "String",
      is_required: "Yes",
      description: "Our system order ID or partner system order ID",
    },
    {
      param_name: "flat_object",
      type: "Bool",
      is_required: "Yes",
      description:
        "Pass 'true' for a flat object; otherwise, a nested object will be returned in the response",
    },
  ];

  const response_params = [
    {
      param_name: "status",
      description:
        "It indicates the request status, with possible values: 'SUCCESS' or 'ERROR'",
    },
    {
      param_name: "message",
      description: "It indicates the success or failed message",
    },
    {
      param_name: "data.txn_status",
      description:
        "It indicates the recharge transaction status, with possible values: 'SUCCESS', 'PENDING', 'FAILED'",
    },
    {
      param_name: "data.txn_description",
      description:
        "It indicates the recharge transaction success or failed message",
    },
    {
      param_name: "data.order_id",
      description: "Our System Order ID",
    },
    {
      param_name: "data.partner_order_id",
      description: "Partner System Order ID",
    },
    {
      param_name: "data.operator_ref_id",
      description: "Operator Reference ID",
    },
    {
      param_name: "timestamp",
      description: "Timestamp of when the request was made",
    },
  ];

  return (
    <div className="">
      <h2 className="docs-h4">Transaction Status API</h2>
      <p className="docs-p">
        This API checks the status of a specific recharge transaction by using
        the Order ID.
      </p>
      <h2 className="docs-h4">Sample Request (GET/POST Method)</h2>
      <div className="docs-example">
        <code className="docs-code">GET {sampleRequest}</code>
      </div>

      <h2 className="docs-h4">Request Parameters</h2>
      <table className="docs-table">
        <thead>
          <tr>
            <th className="docs-th">Parameter</th>
            <th className="docs-th">Type</th>
            <th className="docs-th">Required</th>
            <th className="docs-th">Description</th>
          </tr>
        </thead>
        <tbody>
          {request_params.map((e, key) => (
            <tr key={key}>
              <td className="docs-td">
                <code className="docs-code">{e.param_name}</code>
              </td>
              <td className="docs-td">{e.type}</td>
              <td className="docs-td">{e.is_required}</td>
              <td className="docs-td">{e.description}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <h2 className="docs-h4">Sample Response (JSON Format)</h2>
      <pre className="docs-pre">
        <code className="docs-code">
          <JsonFormatter
            json={sampleResponse}
            //  tabWith="4"
            jsonStyle={jsonStyle}
          />
        </code>
      </pre>
      <h2 className="docs-h4">Response Parameters</h2>
      <table className="docs-table">
        <thead>
          <tr>
            <th className="docs-th">Parameter</th>
            <th className="docs-th">Description</th>
          </tr>
        </thead>
        <tbody>
          {response_params.map((e, key) => (
            <tr key={key}>
              <td className="docs-td">
                <code className="docs-code">{e.param_name}</code>
              </td>
              <td className="docs-td">{e.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TxnStatusApi;
