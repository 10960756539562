import React, { useEffect, useContext, useState, useRef } from "react";
import Swal from "sweetalert2";

import AuthService from "../../../services/Admin/AuthService";
import LoadingContext from "../../../store/loading-context";
import HttpError from "../../../utill/HttpError";
import LoginOtpDialog from "./LoginOtpDialog";
//import LoginOtpDialog from "./LoginOtpDialog";

// import "./Login.module.css";

const Login = () => {
  const loadingCtx = useContext(LoadingContext);

  const [errMsg, setErrMsg] = useState(true);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [token, setToken] = useState(true);
  const [isAgree, setIsAgree] = useState(true);

  const userIdRef = useRef();
  const passwordRef = useRef();

  const loginHandler = async (e) => {
    e.preventDefault();

    const userId = userIdRef.current.value;
    const password = passwordRef.current.value;

    // if (!latitude || !longitude) {
    //   Swal.fire(
    //     "Opps!",
    //     "Unable to retrieve your location, please enable location access in your browser",
    //     "error"
    //   );
    //   return;
    // }

    if (!isAgree) {
      Swal.fire(
        "Opps!",
        "Please accept to the Terms & Conditions and Privacy Policy",
        "error"
      );
      return;
    }

    if (!userId) {
      Swal.fire("Opps!", "Invalid User ID", "error");
      return;
    }

    if (!password) {
      Swal.fire("Opps!", "Invalid Password", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const data = await AuthService.sendOtp(
        userId,
        password,
        latitude,
        latitude
      );

      if (!data) {
        throw new Error("Request Failed!");
      }

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        const token = data.data.token;
        setToken(token);
        setErrMsg(message);
        setOpen(true);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const getLocation = () => {
    if (!navigator.geolocation) {
      Swal.fire(
        "Opps!",
        "Geolocation is not supported by your browser",
        "error"
      );
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        () => {
          Swal.fire(
            "Opps!",
            "Unable to retrieve your location, please enable location access in your browser",
            "error"
          );
        }
      );
    }
  };

  useEffect(() => {
    getLocation();
    isLoggedIn();
  });

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleIsAgree = (v) => {
    setIsAgree(v);
  };

  // ** AUTO LOGIN ** //
  const isLoggedIn = () => {
    const token = AuthService.getAccessToken();
    if (token) {
      window.location.href = "admin/dashboard";
    }
  };
  // ** AUTO LOGIN ** //

  return (
    <React.Fragment>
      <div class="login-box">
        <div class="card card-outline card-primary">
          <div class="card-header text-center">
            <div class="h1">
              <img src="/assets/img/logo.png" alt="logo" width="150px" />
            </div>
          </div>
          <div class="card-body">
            <h5 className="text-center">Welcome Back!</h5>
            <p class="login-box-msg">Login to access to your account.</p>
            <form autoComplete="off">
              <div class="input-group mb-3">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-user"></span>
                  </div>
                </div>
                <input
                  type="text"
                  ref={userIdRef}
                  class="form-control"
                  placeholder="User ID"
                />
              </div>
              <div class="input-group mb-3">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-lock"></span>
                  </div>
                </div>
                <input
                  type="password"
                  ref={passwordRef}
                  class="form-control"
                  placeholder="Password"
                />
              </div>

              <div class="row">
                <button
                  type="button"
                  onClick={loginHandler}
                  class="btn btn-primary btn-block"
                >
                  Login
                </button>
              </div>

              <div class="row mt-2">
                <div class="col-12 mx-auto">
                  <p class="float-right">
                    <a href="#">I forgot my password</a>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <LoginOtpDialog
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        errMsg={errMsg}
        token={token}
      />
    </React.Fragment>
  );
};

export default Login;
