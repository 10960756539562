import React from "react";
import JsonFormatter from "react-json-formatter";

const TransactionApi = () => {
  const jsonStyle = {
    propertyStyle: { color: "red" },
    stringStyle: { color: "green" },
    numberStyle: { color: "darkorange" },
  };

  const sampleRequest = `https://api.formaxpay.com/api/recharge/transaction?authorization=UVRNRlRKckJjRWt5RnBETGtCSGpkV0dMYnF&operator_id=1&number=9836114520&amount=10&p_order_id=TXN90600585937504&flat_object=true`;

  const sampleResponse = {
    status: "SUCCESS",
    message: "Transaction Successful",
    number: "9836114520",
    amount: 10,
    operator_id: "1",
    operator_name: "Airtel",
    debit_amount: "9.80",
    opening_bal: "505.00",
    closing_bal: "495.20",
    order_id: "R51318359375000FQGCZ",
    partner_order_id: "TXN90600585937504",
    operator_ref_id: "4487411496",
    commercial_amount: "0.20",
    tds_amount: "0.00",
    timestamp: "2022-01-01 21:6:24",
  };

  const request_params = [
    {
      param_name: "authorization",
      type: "String",
      is_required: "Yes",
      description: "The Authorization will provided by Formax Pay Team",
    },
    {
      param_name: "operator_id",
      type: "String",
      is_required: "Yes",
      description:
        "Operator ID (Refer to the Operator ID list on the my commission page)",
    },
    {
      param_name: "number",
      type: "String",
      is_required: "Yes",
      description: "The mobile or DTH number to be recharged.",
    },
    {
      param_name: "amount",
      type: "String",
      is_required: "Yes",
      description: "The recharge amount to be processed",
    },
    {
      param_name: "p_order_id",
      type: "String",
      is_required: "Yes",
      description: "The partner's unique order ID to track the transaction",
    },
    {
      param_name: "flat_object",
      type: "Bool",
      is_required: "Yes",
      description:
        "Pass 'true' for a flat object; otherwise, a nested object will be returned in the response",
    },
  ];

  const response_params = [
    {
      param_name: "status",
      description:
        "It indicates the recharge request status, with possible values: 'SUCCESS', 'PENDING', 'FAILED'",
    },
    {
      param_name: "message",
      description: "It indicates the success or failed message",
    },
    {
      param_name: "data.order_id",
      description: "Our System Order ID",
    },
    {
      param_name: "data.partner_order_id",
      description: "Partner System Order ID",
    },
    {
      param_name: "data.operator_ref_id",
      description: "Operator Reference ID",
    },
    {
      param_name: "timestamp",
      description: "Timestamp of when the request was made",
    },
  ];

  return (
    <div className="">
      <h2 className="docs-h4">Recharge Transaction API</h2>
      <p className="docs-p">
        This API allows seamless initiation of mobile and DTH recharge
        transactions, providing instant processing and real-time status updates
        for each request.
      </p>
      <h2 className="docs-h4">Sample Request (GET/POST Method)</h2>
      <div className="docs-example">
        <code className="docs-code">GET {sampleRequest}</code>
      </div>

      <h2 className="docs-h4">Request Parameters</h2>
      <table className="docs-table">
        <thead>
          <tr>
            <th className="docs-th">Parameter</th>
            <th className="docs-th">Type</th>
            <th className="docs-th">Required</th>
            <th className="docs-th">Description</th>
          </tr>
        </thead>
        <tbody>
          {request_params.map((e, key) => (
            <tr key={key}>
              <td className="docs-td">
                <code className="docs-code">{e.param_name}</code>
              </td>
              <td className="docs-td">{e.type}</td>
              <td className="docs-td">{e.is_required}</td>
              <td className="docs-td">{e.description}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <h2 className="docs-h4">Sample Response (JSON Format)</h2>
      <pre className="docs-pre">
        <code className="docs-code">
          <JsonFormatter
            json={sampleResponse}
            //  tabWith="4"
            jsonStyle={jsonStyle}
          />
        </code>
      </pre>
      <h2 className="docs-h4">Response Parameters</h2>
      <table className="docs-table">
        <thead>
          <tr>
            <th className="docs-th">Parameter</th>
            <th className="docs-th">Description</th>
          </tr>
        </thead>
        <tbody>
          {response_params.map((e, key) => (
            <tr key={key}>
              <td className="docs-td">
                <code className="docs-code">{e.param_name}</code>
              </td>
              <td className="docs-td">{e.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionApi;
