import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Templete from "../Templete/Templete";

import TransactionApi from "./API-docs/TransactionApi";
import BalanceApi from "./API-docs/BalanceApi";
import TxnStatusApi from "./API-docs/TxnStatusApi";

import "./API-docs/style.css";
import ComplaintApi from "./API-docs/ComplaintApi";
import OperatorListApi from "./API-docs/OperatorListApi";
import WebhookApi from "./API-docs/WebhookApi";

const ApiDocumentation = () => {
  const history = useHistory();

  const [activeTab, setActiveTab] = useState("Transaction API");

  const [tabs, setTabs] = useState([
    { name: "Transaction API" },
    { name: "TXN Status API" },
    { name: "Callback Response" },
    { name: "Complaint API" },
    { name: "Operator List API" },
    { name: "Balance API" },
  ]);

  return (
    <React.Fragment>
      <Templete t1="Home" t2="API Docs">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-default">
                  <div class="card-header">
                    <h3 class="card-title">API Docs</h3>

                    <div class="card-tools">
                      <button
                        type="button"
                        class="btn btn-sm bg-gradient-success"
                        onClick={() => {
                          history.push("/user/api_credentials");
                        }}
                      >
                        <i class="fa fa-code"></i> API Credentials
                      </button>
                    </div>
                  </div>

                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 ">
                        <div class="card card-primary card-tabs">
                          <div class="card-header p-0 border-bottom-0">
                            <ul class="nav nav-tabs" role="tablist">
                              {tabs.map((e, index) => {
                                return (
                                  <li class="nav-item">
                                    <a
                                      class={`nav-link ${
                                        activeTab === e.name ? "active" : ""
                                      }`}
                                      id={index}
                                      data-toggle="pill"
                                      role="tab"
                                      onClick={() => {
                                        setActiveTab(e.name);
                                      }}
                                    >
                                      {e.name}
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>

                          <div class="card-body">
                            {activeTab === "Transaction API" && (
                              <TransactionApi />
                            )}
                            {activeTab === "TXN Status API" && <TxnStatusApi />}
                            {activeTab === "Callback Response" && (
                              <WebhookApi />
                            )}
                            {activeTab === "Complaint API" && <ComplaintApi />}
                            {activeTab === "Operator List API" && (
                              <OperatorListApi />
                            )}
                            {activeTab === "Balance API" && <BalanceApi />}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default ApiDocumentation;
