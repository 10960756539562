import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { ToWords } from "to-words";

import Swal from "sweetalert2";
import Templete from "../Templete/Templete";
import AuthService from "../../../services/Admin/AuthService";
import RechargeService from "../../../services/Admin/RechargeService";
import NsdlPanCardService from "../../../services/Admin/NsdlPanCardService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import common from "../../../utill/Common";

const ApiBalances = () => {
  const loadingCtx = useContext(LoadingContext);

  const [data, setData] = useState();

  useEffect(() => {
    apiBalancesHandler();
    getNsdlApiBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // page, size, searchText, fromDate, toDate, status, productId

  const apiBalancesHandler = async (id) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await RechargeService.apiBalances(token, id);

      const status = responseData.status;
      const message = responseData.message;

      if (status == "SUCCESS") {
        //
        const data = responseData.data;
        setData(data);
        //
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const [nsdlBal, setNsdlBal] = useState(false);

  const getNsdlApiBalance = async () => {
    if (common.companyName !== "FORMAX") {
      return;
    }

    try {
      const token = AuthService.getAccessToken();

      const responseData = await NsdlPanCardService.getApiBalance(token);
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setNsdlBal(responseData.data.amount);
      } else {
        //  Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
  };

  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  const getWord = (amount) => {
    if (parseFloat(amount)) {
      return toWords.convert(parseFloat(amount).toFixed(0));
    } else {
      return "";
    }
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="API Balances">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">API Balances</h3>
                  </div>

                  <div class="card-body">
                    {common.companyName == "FORMAX" && (
                      <div className="row">
                        <div class="col-lg-3 col-6">
                          <div class="small-box bg-info">
                            <div class="inner">
                              <h3>{nsdlBal ? "₹" + nsdlBal : "null"}</h3>
                              <small>
                                {getWord(nsdlBal ? parseInt(nsdlBal) : 0)}
                              </small>
                              <p>NSDL API Balance</p>
                            </div>
                            <div class="icon">
                              <i class="fas fa-wallet"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="table-responsive pt-3">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>API NAME</th>
                            <th>BALANCE</th>
                            <th>LOG</th>
                          </tr>
                        </thead>
                        {data &&
                          data.map((val, key) => {
                            return (
                              <tr key={key}>
                                <td>{val.api_name}</td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {parseFloat(val.balance).toFixed(2)}
                                  </span>{" "}
                                  <br />
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "bold",
                                      color: "red",
                                    }}
                                  >
                                    {getWord(val.balance)}
                                  </span>
                                </td>
                                <td>
                                  <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => {
                                      alert(JSON.stringify(val.response));
                                    }}
                                  >
                                    View
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default ApiBalances;
