import React, { useEffect, useState, useRef } from "react";
import JsonFormatter from "react-json-formatter";

// import CompanyService from "../../../../services/User/CompanyService";

const OperatorListApi = () => {
  useEffect(() => {
    fetchData();
  }, []);

  const [company, setCompany] = useState();

  const fetchData = async () => {
    // const _company = await CompanyService.getCompanyData();
    // if (_company) {
    //   setCompany(_company);
    // }
  };

  const jsonStyle = {
    propertyStyle: { color: "red" },
    stringStyle: { color: "green" },
    numberStyle: { color: "darkorange" },
  };

  const sampleRequest = `https://api.formaxpay.com/api/recharge/operator_list?authorization=UVRNRlRKckJjRWt5RnBETGtCSGpkV0dMYnF`;

  const sampleResponse = {
    status: "SUCCESS",
    message: "SUCCESS",
    data: [
      {
        id: 1,
        name: "Airtel",
        status: "Enabled",
      },
      {
        id: 2,
        name: "BSNL Topup",
        status: "Enabled",
      },
      {
        id: 3,
        name: "BSNL Special",
        status: "Enabled",
      },
      {
        id: 4,
        name: "JIO",
        status: "Enabled",
      },
      {
        id: 5,
        name: "Vi",
        status: "Enabled",
      },
      {
        id: 6,
        name: "Airtel Digital TV",
        status: "Enabled",
      },
      {
        id: 7,
        name: "Dish TV",
        status: "Enabled",
      },
      {
        id: 8,
        name: "Sun Direct",
        status: "Enabled",
      },
      {
        id: 9,
        name: "Tata Sky",
        status: "Enabled",
      },
      {
        id: 10,
        name: "Videocon D2H",
        status: "Enabled",
      },
      {
        id: 47,
        name: "Google Play",
        status: "Enabled",
      },
    ],
  };

  const request_params = [
    {
      param_name: "authorization",
      type: "String",
      is_required: "Yes",
      description: "The Authorization will provided by Formax Pay Team",
    },
  ];

  const response_params = [
    {
      param_name: "status",
      description:
        "It indicates the request status, with possible values: 'SUCCESS' or 'ERROR'",
    },
    {
      param_name: "message",
      description: "It indicates the success or failed message",
    },
    {
      param_name: "data.id",
      description: "Operator ID",
    },
    {
      param_name: "data.name",
      description: "Operator Name",
    },
    {
      param_name: "data.status",
      description:
        "It indicates the operator status, with possible values: 'Enabled' or 'Disabled'",
    },
  ];

  return (
    <div className="">
      <h2 className="docs-h4">Operator List API</h2>
      <p className="docs-p">
        This API is used to fetch recharge operator list.
      </p>
      <h2 className="docs-h4">Sample Request (GET/POST Method)</h2>
      <div className="docs-example">
        <code className="docs-code">GET {sampleRequest}</code>
      </div>

      <h2 className="docs-h4">Request Parameters</h2>
      <table className="docs-table">
        <thead>
          <tr>
            <th className="docs-th">Parameter</th>
            <th className="docs-th">Type</th>
            <th className="docs-th">Required</th>
            <th className="docs-th">Description</th>
          </tr>
        </thead>
        <tbody>
          {request_params.map((e, key) => (
            <tr key={key}>
              <td className="docs-td">
                <code className="docs-code">{e.param_name}</code>
              </td>
              <td className="docs-td">{e.type}</td>
              <td className="docs-td">{e.is_required}</td>
              <td className="docs-td">{e.description}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <h2 className="docs-h4">Sample Response (JSON Format)</h2>
      <pre className="docs-pre">
        <code className="docs-code">
          <JsonFormatter
            json={sampleResponse}
            //  tabWith="4"
            jsonStyle={jsonStyle}
          />
        </code>
      </pre>
      <h2 className="docs-h4">Response Parameters</h2>
      <table className="docs-table">
        <thead>
          <tr>
            <th className="docs-th">Parameter</th>
            <th className="docs-th">Description</th>
          </tr>
        </thead>
        <tbody>
          {response_params.map((e, key) => (
            <tr key={key}>
              <td className="docs-td">
                <code className="docs-code">{e.param_name}</code>
              </td>
              <td className="docs-td">{e.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OperatorListApi;
